body {
	max-width: 1600px; 
	margin: 0 auto !important; 
	float: none !important; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color: #FFF;
}

.error{
  margin-top: 15px;
  color: #E5625E;
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
}

.dataTip{
  display: inline;
  font-weight: 650;
  color: #4357AC;
  cursor: pointer;
}

button{
  min-width: 75px;
  height: 30px;
  margin: 0 15px;
  border-radius: 5px;
  font-weight: 700;
  background-color: #48A9A6;
  cursor: pointer;
}

button:hover {
  background-color: #FEC369;
  box-shadow: 0 0 10px 2px #595358;
}

.mntn{
  display: block;
  max-width: 250px;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .mntn {
    max-width: 150px;
  }
}

@font-face {
  font-family: 'nerdland';
  src: url('./assets/fonts/NederlandFantasy.ttf')
}
.font-nerd {
  font-family: 'nerdland';
}
@font-face {
  font-family: 'handy';
  src: url('./assets/fonts/BabyDoll.ttf')
}
.font-handy{
  font-family: 'handy';
}
@font-face {
  font-family: 'Nelship';
  src: url('./assets/fonts/Nelship.ttf')
}
.font-nels{
  font-family: 'Nelship';
}

.text-pop-up-top {
	-webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}