#choice_form
{
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 75px;
    left: 15px;
    right: 15px;
}
#choice_form button
{
   height: 40px;
   margin: 2px;

}



@media all and (min-width: 600px){

    .dash-main
    {
        margin: 50px 0;
        height:500px;
    }
    #choice_form
    {
        position: unset;
        margin: auto;
        width: 440px;
        margin-bottom: auto;
    }

}