.viewport {
  position: fixed;
  top:40px;
  bottom:130px;
  left:10px;
  right:10px;
  text-align: left;
  padding:20px;
  overflow-y: auto;
  border-radius: 8px;
  background-image: url("../../assets/images/paper.jpg");
}
.viewport p
{
  line-height: 1.5em;
  margin-top: 2px;
}
@media all and (min-width: 600px){
  .viewport {
    position: unset;
    width: 400px;
    height: 400px;
    margin: auto;
    margin-bottom: 5px;
  }
}