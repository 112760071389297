main
{
    position: fixed;
    bottom: 65px;
    top:40px;
    right:0px;
    left:0px;
    overflow-y: auto;
    
}

.intro{
    margin: auto;
    padding: 20px;
}

.intro p{
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5em;
}

.intro h4
{
    text-align: center;
}

.header{
    text-align: center;
    font-size: 3.5em;
    font-family: 'nerdland';
    margin-top: 10px;
}

.sub-head{
    font-family: 'Nelship';
    text-align: center;
    margin: 10px auto;
    padding: 20px;
}

.main-inner{
    margin-top: -50px;
    
}

.main-nav{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 1.5em;
    padding: 5px;
    color: #48A9A6;
}

.main-link{
    text-decoration: none;
}

.main-link:hover{
    color: #FEC369;
}

@media only screen and (min-width: 600px) {
   
    .intro
    {
        width: 650px;
        margin: auto;
        padding: 20px;
        
        
    }
    .intro p
    {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        width: 500px;
    }
    .intro h3
    {
        text-align: center;
    }
    .intro h4
    {
        text-align: center;
    }
    #demo_holder
    {
        width:200px;
        
        border: 1px solid rgb(189, 189, 189);
        margin: auto;
        padding: 10px 10px 10px 30px;
    }
    .demo_data
    {  
        margin: auto;   
    }
    .header {
      margin-top: 100px;
      font-size: 2.8em;
    }
    
    
}
