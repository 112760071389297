
.acc-header{
    font-weight: bold;
    margin: 15px;
    background-color: transparent;
    border: none;
}

.acc-btn:hover{
    background-color: transparent;
    box-shadow: none;
}

.expandable_btn_content{
    overflow: hidden;
    transition: 1s ease;
    text-align: left;
    padding-left: 30px;
    padding-right: 30PX;
    margin:auto;
    line-height: 1.5em;
}

@media all and (min-width: 600px){
    .expandable_btn_content{
        width: 500px;
        margin:auto;
    }
  }